/**=====================
    44. Tabs CSS Start
==========================**/
// .nav-pills, .nav-tabs {
//   margin-bottom: 30px;
// }
.nav-pills {
  .nav-link.active, .show > .nav-link {
    background-color: $primary-color;
  }
}
.nav{
  i{
    margin-right: 10px;
  }
}

$nav-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$nav-color:$primary-color,
$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $nav-name {
  $i: index($label-name, $var);
  .nav-#{$var} , .nav-pills.nav-#{$var} {
    .nav-link{
      color: nth($nav-color, $i);
    }
    .nav-link.active, .show > .nav-link {
      background-color: nth($nav-color, $i) !important;
      color: $white;
    }
  }
}
.nav-right{
  justify-content: flex-end;
}
.nav-tabs.nav-bottom {
  .nav-item {
    .nav-link.active,.nav-link.focus,.nav-link.hover{
      border-color: $white #dee2e6 #dee2e6;
    }
  }
}
.tab-content{
  ~ .nav-tabs{
    &.nav-material {
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
}
// Material style css
.nav-material.nav-tabs{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  .nav-item{
    .nav-link{
      font-size: 16px;
      color: $dark-color;
      text-align: center;
      padding:10px 25px;
      border: 0;
    }
  }
  .nav-link.active,.nav-item.show,.nav-link:focus,.nav-link:hover {
    color: $primary-color;
  }
  .material-border{
    border-bottom: 2px solid $primary-color;
    transition: all 0.3s ease;
    opacity: 0;
  }
  .nav-link.active ~ .material-border{
    transition: all 0.3s ease;
    opacity: 1;
  }
}
.nav-material.nav-left{
  .nav-link{
    border-left: 2px solid transparent;
    transition: all 0.3s ease;
    color: $dark-color;
  }
  .nav-link.active,.show > .nav-link{
    background-color: transparent;
    border-left-color: $primary-color;
    color: $primary-color;
    border-radius: 0;
    transition: all 0.3s ease;
  }
}
.nav-material.nav-right{
  .nav-link{
    border-right: 2px solid transparent;
    transition: all 0.3s ease;
    color: $dark-color;
    text-align: right;
  }
  .nav-link.active,.show > .nav-link{
    background-color: transparent;
    border-right-color: $primary-color;
    color: $primary-color;
    border-radius: 0;
    transition: all 0.3s ease;
  }
}
$m-nav-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$nav-color:$primary-color,
$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $m-nav-name {
  $i: index($label-name, $var);
  .nav-material.nav-#{$var} {
    .nav-link.active,.nav-item.show,.nav-link:focus,.nav-link:hover {
      color: nth($nav-color, $i);
      background-color: transparent;
    }
    .material-border{
      border-bottom: 2px solid nth($nav-color, $i);
    }
  }
  .nav-material.nav-left.nav-#{$var}{
    .nav-link.active,.show > .nav-link{
      border-left-color: nth($nav-color, $i);
      color: nth($nav-color, $i);
    }
  }
  .nav-material.nav-right.nav-#{$var}{
    .nav-link.active,.show > .nav-link{
      border-right-color: nth($nav-color, $i);
      color: nth($nav-color, $i);
    }
  }
}
.tabbed-card{
  ul{
    position: absolute;
    right: 15px;
    top: 22px;
    &.nav-material{
      &.nav-tabs{
        top: 0;
        .nav-item{
          float: right;
          .nav-link{
            padding: 28px 15px;
          }
        }
      }
    }
  }
}


.tabbed-card{
  .nav-tabset{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    span{
      display: block;
      padding: 0.5rem 1rem;
    }
    .nav-tab{
      &.active{
        border-radius: 15px;
      }
    }
  }
}
@each $tab-name, $tab-color in (primary, $primary-color),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (dark, $dark-color),
  (warning, $warning-color)
{
  .nav-#{$tab-name} {
    .nav-tabset{
      .nav-tab{
        color: $tab-color;
        &.active{
          background-color: $tab-color;
          color: $white !important;
        }
      }
    }
  }
}
@each $border-name, $border-color in (primary, $primary-color),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (dark, $dark-color),
  (warning, $warning-color)
{ 
  .borderb-tab-#{$border-name} {
    .nav-tabset{
      right: 34px;
      span{
        padding: 8px 16px 28px;
      }
      .nav-tab{
        border-radius: 0 !important;
        &.active{
          border-bottom: 2px solid $border-color;
          color: $border-color;
        }
      }
    }
  }
}
/**=====================
     44. Tabs CSS Ends
==========================**/
